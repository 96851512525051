import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';  // Importa useNavigate
import { fetchExcelReport, getDetailStatistics, getErrorTransactions } from './dashboard-apis';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { Modal, Button, Spinner } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2'
import moment from 'moment';


interface ErrorLog {
  id: number;
  product: string;
  provider: string;
  service: string;
  family: string;
  status: string;
  client: string;
  createdAt: string;
  requestuuid?: string;
}

interface Statistic {
  type: string;
  service: string;
  providerName: string;
  totalCount: number;
  successfulCount: number;
  failedCount: number;
  hubFailedCount: number;
  providerFailedCount: number;
  category: string;
  successfulRequests: number;
  customerName?: string;
  requestuuid?: string;

}

interface ContactProvider {
  id: number;
  providerId: number;
  ecosystemName: string;
  providerName: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  avatarUrl: string;
  createdAt: string;
  updatedAt: string;
}

const formatJson = (jsonString: string | null): string => {
  if (jsonString === null) {
    return '';
  }
  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch (e) {
    return jsonString;
  }
};

const DetailMonitoringAlerts: React.FC = () => {
  const { category, client = 'Global' } = useParams();
  const navigate = useNavigate();  // Hook para navegar

  const [statistics, setStatistics] = useState<Statistic[]>([]);
  const [transactions, setTransactions] = useState<ErrorLog[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeFilter, setTimeFilter] = useState('1 Hora');

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [selectedRequestUUID, setSelectedRequestUUID] = useState<string | null>(null);
  const [providerRequest, setProviderRequest] = useState<string | null>(null);
  const [providerResponse, setProviderResponse] = useState<string | null>(null);
  const [customerRequest, setCustomerRequest] = useState<string | null>(null);
  const [customerResponse, setCustomerResponse] = useState<string | null>(null);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [contacts, setContacts] = useState<ContactProvider[]>([]);
  const [timezone, setTimezone] = useState('');


  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getDetailStatistics(client, timeFilter, startDate, endDate, category);
        setStatistics(response.data.errorLogs || []);
        setTransactions(response.data.transactions || []);
        setContacts(response.data.contactProviders || []);
        setTimezone(response.data.userTimezone)
      } catch (error) {
        console.error('Error fetching monitoring statistics:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [client, timeFilter, startDate, endDate, category]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!selectedRequestUUID) return;
      setIsModalLoading(true);
      try {
        const response = await getErrorTransactions(selectedRequestUUID);
        const requestData = response.data.request;
        setProviderRequest(requestData.providerRequest);
        setProviderResponse(requestData.providerResponse);
        setCustomerRequest(requestData.customerRequest);
        setCustomerResponse(requestData.customerResponse);
      } catch (error) {
        console.error('Error fetching error transactions:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsModalLoading(false);
      }
    };

    fetchTransactions();
  }, [selectedRequestUUID]);

  const handleTimeFilterChange = (filter: string) => {
    setTimeFilter(filter);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleShowModal = (requestuuid: string) => {
    setSelectedRequestUUID(requestuuid);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setProviderRequest(null);
    setProviderResponse(null);
  };

  const handleDownload = async () => {
    try {
      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Generando reporte...</span>
        </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions);
  
      let computedStartDate: string | null = null;
      let computedEndDate: string | null = null;
  
      // Si el tiempo es "Personalizado", tomamos las fechas del estado startDate y endDate
      if (timeFilter === 'Personalizado') {
        if (!startDate || !endDate) {
          console.error("Fechas no proporcionadas para intervalo Personalizado.");
          Swal.close();
          return;
        }
  
        // Asegúrate de que startDate y endDate sean cadenas en formato YYYY-MM-DD o similar
        computedStartDate = startDate.trim();
        computedEndDate = endDate.trim();
      }
  
      // Aquí llamamos a fetchExcelReport pasando el timeFilter y, opcionalmente, las fechas
      const url = await fetchExcelReport(
        timeFilter,        // Intervalo
        category ?? '',    // Categoría
        client ?? 'Global',// Cliente
        computedStartDate, // Puede ser null si no es personalizado
        computedEndDate    // Puede ser null si no es personalizado
      );
  
      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'admin_error_log_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }
  
      Swal.close();
    } catch (err: any) {
      Swal.close();
      console.error(err);
    }
  };
  

  // Mapeo de categorías a URLs
  const reportUrls: { [key: string]: string } = {
    'KYC': '/validate-identity-reports/list',
    'Passport': '/recharges-reports/list',
    'SR': '/risk-indicators-reports/list',
    'ERP': '/odoo-reports/list',
    'JOURNEYS': '/journey-event-reports/list',
    'HBM': '/hbm-reports/list',
    'DOCUMENTOS': '/generacion-documentos-reports/list',
    'SMS': '/sms-reports/list',

    // Agrega otras categorías si es necesario
  };

  const getReportUrl = (category: string | undefined): string => {
    if (!category) return '/reports/list'; // URL por defecto si category es undefined
    const normalizedCategory = category.toUpperCase().trim();
    const url = reportUrls[normalizedCategory];
    return url ? url : '/reports/list'; // Si no se encuentra la categoría, redirige a '/reports/list'
  };


  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error: {error}</div>;
  }

  return (
    <div className='container-fluid mt-4 detail-error-log'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-right'>
          <div className='d-flex align-items-center'>
            <button className='btn btn-secondary' onClick={() => navigate(-1)}>
              Regresar
            </button>
          </div>


          <div className='btn-group ms-auto' role='group' aria-label='Time filter'>
            {['1 Hora', '1 día', '7 días', 'Personalizado'].map((filter) => (
              <button
                key={filter}
                type='button'
                className={`btn ${timeFilter === filter ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTimeFilterChange(filter)}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>

        {timeFilter === 'Personalizado' && (
          <div className='card-body'>
            <div className='row mb-4'>
              <div className='col-md-6'>
                <label htmlFor='startDate'>Fecha de inicio</label>
                <input
                  type='date'
                  id='startDate'
                  className='form-control'
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='endDate'>Fecha de fin</label>
                <input
                  type='date'
                  id='endDate'
                  className='form-control'
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>
        )}
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='card'>
                <div className='card-body'>
                  <h3>Solicitudes</h3>
                  <table className='table table-striped'>
                    <thead>
                      <tr>
                        <th>Solicitante</th>
                        <th>Responsable</th>

                        <th>Producto</th>
                        <th>Solicitudes</th>
                        <th>Respuestas exitosas</th>
                        <th>Respuestas fallidas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statistics.map((stat, index) => (
                        <tr key={index}>
                          <td>{stat.customerName}</td>
                          <td>{stat.category === 'Errores proveedor' ? stat.providerName : 'HUB'}</td>
                          <td>{stat.service}</td>
                          <td>{stat.totalCount}</td>
                          <td className='text-success'>{stat.successfulCount}</td>
                          <td className='text-danger'>{stat.failedCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card'>
                <div className='card-body'>
                  <h3>Proveedores</h3>
                  <div className='provider-list'>
                    {contacts.map((contact, index) => (
                      <div className='provider-item' key={index}>
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Provider Avatar' className='provider-avatar' />
                        <div className='provider-info'>
                          <h5>{contact.providerName} - {contact.contactName}</h5>
                          <p>{contact.email}</p>
                          <p>{contact.phoneNumber}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <h3>Transacciones fallidas</h3>
                    <div className='mt-3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <button
                        className='btn btn-primary'
                        onClick={() => navigate(getReportUrl(category))}
                      >
                        Ir al reporte de consumo
                      </button>
                      <button className='btn btn-secondary ms-2' onClick={handleDownload} >Descargar logs</button>
                    </div>
                    <table className='table table-striped mt-3'>
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>UUID</th>
                          <th>Proveedor</th>
                          <th>Servicio</th>
                          <th>Socio comercial</th>
                          <th>Tipo</th>
                          <th>Fecha de registro</th>
                          <th>Ver detalles</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((transaction, index) => (
                          <tr key={transaction.id}>
                            <td>{index + 1}</td>
                            <td>{transaction.requestuuid}</td>
                            <td>{transaction.provider}</td>
                            <td>{transaction.service}</td>
                            <td>{transaction.client}</td>
                            <td>{transaction.family}</td>
                            <td>
                              {moment(transaction.createdAt).tz(timezone).format('DD-MM-YYYY HH:mm:ss')}
                            </td>

                            <td>
                              <button
                                className='btn btn-link'
                                onClick={() => handleShowModal(transaction.requestuuid || '')}
                              >
                                <i className='fas fa-eye'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de la Transacción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isModalLoading ? (
            <div className='d-flex justify-content-center'>
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Cargando...</span>
              </Spinner>
            </div>
          ) : (
            <div>
              <h5>Provider Request</h5>
              <pre className='json-pre'>{formatJson(providerRequest)}</pre>

              <h5>Provider Response</h5>
              <pre className='json-pre'>{formatJson(providerResponse)}</pre>

              <h5>Customer Request</h5>
              <pre className='json-pre'>{formatJson(customerRequest)}</pre>

              <h5>Customer Response</h5>
              <pre className='json-pre'>{formatJson(customerResponse)}</pre>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { DetailMonitoringAlerts };
