import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { fetchCustomers, getMonitoringStatistics } from './dashboard-apis';
import { AlertLog, ICustomer, MostFailedService, Statistic, Transaction } from './models';


const DashboardAlerts: React.FC = () => {
  const [statistics, setStatistics] = useState<Statistic[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [timeFilter, setTimeFilter] = useState('1 Hora');
  const [client, setClient] = useState('Global');
  const [startDate, setStartDate] = useState<string>(''); // Estado para la fecha de inicio personalizada
  const [endDate, setEndDate] = useState<string>(''); // Estado para la fecha de fin personalizada
  const [alertsLog, setAlertsLog] = useState<AlertLog[]>([]);
  const [mostFailedServices, setMostFailedServices] = useState<MostFailedService[]>([]);

  const [customers, setCustomer] = useState<ICustomer[]>([])




  useEffect(() => {
    const fetchData = async () => {
      setError(null)
      try {
        const { customers } = await fetchCustomers();
        setCustomer(customers);

      } catch (err: any) {
        setError(err)
      }
    }

    fetchData()
  }, []) // Arreglo de dependencias vacío


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getMonitoringStatistics(client, timeFilter, startDate, endDate);

        console.log("respuiesta", response)
        setStatistics(response.statistics || []);
        setTransactions(response.transactions || []);
        setAlertsLog(response.alertsLogs || []);
        setMostFailedServices(response.mostFailedServices || []);
      } catch (error) {
        console.error('Error fetching monitoring statistics:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [client, timeFilter, startDate, endDate]);

  const handleTimeFilterChange = (filter: string) => {
    setTimeFilter(filter);
  };

  const handleClientChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setClient(e.target.value);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const getAlertClasses2 = (level: string) => {
    switch (level) {
      case 'OK':
        return { card: 'bg-lightgreen', text: 'text-dark', icon: '🟢', status: 'OK' };
      case 'Primer Nivel':
        return { card: 'bg-lightyellow', text: 'text-dark', icon: '⚠️', status: 'Advertencia' };
      case 'Segundo Nivel':
        return { card: 'bg-lightblue', text: 'text-dark', icon: '🔵', status: 'Crítico' };
      case 'Tercer Nivel':
        return { card: 'bg-lightred', text: 'text-dark', icon: '❌', status: 'Fuera de Servicio' };
      default:
        return { card: 'bg-light', text: 'text-dark', icon: '', status: 'Sin datos' };
    }
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error: {error}</div>;
  }

  return (
    <div className='container-fluid mt-4'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <select className='form-select' value={client} onChange={handleClientChange}>
            <option value="Global">Global</option>
            {customers.map(customer => (
              <option key={customer.id} value={customer.id}>{customer.name}</option>
            ))}
          </select>
          <div className='btn-group' role='group' aria-label='Time filter'>
            {['1 Hora', '1 día', '7 días', 'Personalizado'].map((filter) => (
              <button
                key={filter}
                type='button'
                className={`btn ${timeFilter === filter ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTimeFilterChange(filter)}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>
        {timeFilter === 'Personalizado' && (
          <div className='card-body'>
            <div className='row mb-4'>
              <div className='col-md-6'>
                <label htmlFor='startDate'>Fecha de inicio</label>
                <input
                  type='date'
                  id='startDate'
                  className='form-control'
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className='col-md-6'>
                <label htmlFor='endDate'>Fecha de fin</label>
                <input
                  type='date'
                  id='endDate'
                  className='form-control'
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>
        )}
        <div className='card-body'>
          <div className='row'>
            {statistics.length > 0 ? (
              statistics.map(stat => {
                const mostFailedServiceForType = mostFailedServices.find(service => service.type === stat.type);
                const alertClasses = mostFailedServiceForType ? getAlertClasses2(mostFailedServiceForType.level) : { card: 'bg-light', text: 'text-dark', icon: '', status: 'Sin datos' };
                const tooltipContent = mostFailedServiceForType
                  ? mostFailedServiceForType?.message
                  : 'Operando normalmente';
                return (
                  <div className='col-md-4 ' key={stat.type}>
                    <Link
                      to={`/services/detail-monitoring/${stat.type === 'PASSPORT' ? 'Passport' : stat.type}/${client}`}
                      className='text-decoration-none'
                    >
                      <div className={` mt-4 mb-4 card status-card ${alertClasses.card} ${alertClasses.text}`} data-bs-toggle="tooltip" data-bs-placement="top" title={tooltipContent}>
                        <div className='card-body'>
                          <h5 className='card-title'>{alertClasses.icon} {stat.type}</h5>
                          <p className='card-text'>{stat.successRate.toFixed(2)}% exitosas</p>
                          <p className='card-text'>{stat.totalCount} solicitudes</p>
                          <p className='card-text'>Estatus actual: {alertClasses.status}</p>
                          <div className='progress'>
                            <div className='progress-bar bg-success' style={{ width: `${stat.successRate}%` }}></div>
                            <div className='progress-bar bg-danger' style={{ width: `${100 - stat.successRate}%` }}></div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <p>No hay estadísticas disponibles.</p>
            )}
          </div>
          <div className='row mt-4'>
            <div className='col-md-4'>
              <div className='card'>
                <div className='card-body'>
                  <h3>Alertas</h3>
                  <ul className='list-group'>
                    {alertsLog.length > 0 ? (
                      alertsLog.map(alert => (
                        <li key={alert.id} className='list-group-item'>
                          <div className={`mb-4 alert ${getAlertClasses2(alert.level).card}`}>
                            <strong>{alert.service} - {alert.type}</strong><br />
                            {alert.message}<br />
                            <small>{new Date(alert.createdAt).toLocaleString()}</small>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No hay alertas disponibles.</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='card'>
                <div className='card-body'>
                  <h3>Transacciones</h3>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th>Producto</th>
                          <th>Proveedor</th>
                          <th>Familia</th>
                          <th>Estatus</th>
                          <th>Cliente</th>
                          <th>Fecha de registro</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.length > 0 ? (
                          transactions.map((transaction, index) => (
                            <tr key={index}>
                              <td>{transaction.service}</td>
                              <td>{transaction.provider}</td>
                              <td>{transaction.product}</td>
                              <td className={transaction.status === 'Error' ? 'text-danger' : 'text-success'}>{transaction.status}</td>
                              <td>{transaction.client}</td>
                              <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>No hay transacciones disponibles.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardAlerts };
